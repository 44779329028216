import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _fs2 from "@nodelib/fs.scandir";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const path = _path;
const fsScandir = _fs;

class Settings {
  constructor(_options = {}) {
    this._options = _options;
    this.basePath = this._getValue(this._options.basePath, undefined);
    this.concurrency = this._getValue(this._options.concurrency, Number.POSITIVE_INFINITY);
    this.deepFilter = this._getValue(this._options.deepFilter, null);
    this.entryFilter = this._getValue(this._options.entryFilter, null);
    this.errorFilter = this._getValue(this._options.errorFilter, null);
    this.pathSegmentSeparator = this._getValue(this._options.pathSegmentSeparator, path.sep);
    this.fsScandirSettings = new fsScandir.Settings({
      followSymbolicLinks: this._options.followSymbolicLinks,
      fs: this._options.fs,
      pathSegmentSeparator: this._options.pathSegmentSeparator,
      stats: this._options.stats,
      throwErrorOnBrokenSymbolicLink: this._options.throwErrorOnBrokenSymbolicLink
    });
  }

  _getValue(option, value) {
    return option !== null && option !== void 0 ? option : value;
  }

}

exports.default = Settings;
export default exports;