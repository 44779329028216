import * as _stream2 from "stream";

var _stream = "default" in _stream2 ? _stream2.default : _stream2;

import _async from "../readers/async";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const stream_1 = _stream;
const async_1 = _async;

class StreamProvider {
  constructor(_root, _settings) {
    this._root = _root;
    this._settings = _settings;
    this._reader = new async_1.default(this._root, this._settings);
    this._stream = new stream_1.Readable({
      objectMode: true,
      read: () => {},
      destroy: () => {
        if (!this._reader.isDestroyed) {
          this._reader.destroy();
        }
      }
    });
  }

  read() {
    this._reader.onError(error => {
      this._stream.emit("error", error);
    });

    this._reader.onEntry(entry => {
      this._stream.push(entry);
    });

    this._reader.onEnd(() => {
      this._stream.push(null);
    });

    this._reader.read();

    return this._stream;
  }

}

exports.default = StreamProvider;
export default exports;