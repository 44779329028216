import _sync from "../readers/sync";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const sync_1 = _sync;

class SyncProvider {
  constructor(_root, _settings) {
    this._root = _root;
    this._settings = _settings;
    this._reader = new sync_1.default(this._root, this._settings);
  }

  read() {
    return this._reader.read();
  }

}

exports.default = SyncProvider;
export default exports;