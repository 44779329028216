var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.joinPathSegments = exports.replacePathSegmentSeparator = exports.isAppliedFilter = exports.isFatalError = void 0;

function isFatalError(settings, error) {
  if (settings.errorFilter === null) {
    return true;
  }

  return !settings.errorFilter(error);
}

exports.isFatalError = isFatalError;

function isAppliedFilter(filter, value) {
  return filter === null || filter(value);
}

exports.isAppliedFilter = isAppliedFilter;

function replacePathSegmentSeparator(filepath, separator) {
  return filepath.split(/[/\\]/).join(separator);
}

exports.replacePathSegmentSeparator = replacePathSegmentSeparator;

function joinPathSegments(a, b, separator) {
  if (a === "") {
    return b;
  }
  /**
   * The correct handling of cases when the first segment is a root (`/`, `C:/`) or UNC path (`//?/C:/`).
   */


  if (a.endsWith(separator)) {
    return a + b;
  }

  return a + separator + b;
}

exports.joinPathSegments = joinPathSegments;
export default exports;