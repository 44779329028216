import _async from "./providers/async";
import _stream from "./providers/stream";
import _sync from "./providers/sync";
import _settings from "./settings";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Settings = exports.walkStream = exports.walkSync = exports.walk = void 0;
const async_1 = _async;
const stream_1 = _stream;
const sync_1 = _sync;
const settings_1 = _settings;
exports.Settings = settings_1.default;

function walk(directory, optionsOrSettingsOrCallback, callback) {
  if (typeof optionsOrSettingsOrCallback === "function") {
    new async_1.default(directory, getSettings()).read(optionsOrSettingsOrCallback);
    return;
  }

  new async_1.default(directory, getSettings(optionsOrSettingsOrCallback)).read(callback);
}

exports.walk = walk;

function walkSync(directory, optionsOrSettings) {
  const settings = getSettings(optionsOrSettings);
  const provider = new sync_1.default(directory, settings);
  return provider.read();
}

exports.walkSync = walkSync;

function walkStream(directory, optionsOrSettings) {
  const settings = getSettings(optionsOrSettings);
  const provider = new stream_1.default(directory, settings);
  return provider.read();
}

exports.walkStream = walkStream;

function getSettings(settingsOrOptions = {}) {
  if (settingsOrOptions instanceof settings_1.default) {
    return settingsOrOptions;
  }

  return new settings_1.default(settingsOrOptions);
}

export default exports;
export const __esModule = exports.__esModule,
      Settings = exports.Settings;
const _walkStream = exports.walkStream,
      _walkSync = exports.walkSync,
      _walk = exports.walk;
export { _walkStream as walkStream, _walkSync as walkSync, _walk as walk };