import * as _fs2 from "@nodelib/fs.scandir";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import _common from "./common";
import _reader from "./reader";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const fsScandir = _fs;
const common = _common;
const reader_1 = _reader;

class SyncReader extends reader_1.default {
  constructor() {
    super(...arguments);
    this._scandir = fsScandir.scandirSync;
    this._storage = [];
    this._queue = new Set();
  }

  read() {
    this._pushToQueue(this._root, this._settings.basePath);

    this._handleQueue();

    return this._storage;
  }

  _pushToQueue(directory, base) {
    this._queue.add({
      directory,
      base
    });
  }

  _handleQueue() {
    for (const item of this._queue.values()) {
      this._handleDirectory(item.directory, item.base);
    }
  }

  _handleDirectory(directory, base) {
    try {
      const entries = this._scandir(directory, this._settings.fsScandirSettings);

      for (const entry of entries) {
        this._handleEntry(entry, base);
      }
    } catch (error) {
      this._handleError(error);
    }
  }

  _handleError(error) {
    if (!common.isFatalError(this._settings, error)) {
      return;
    }

    throw error;
  }

  _handleEntry(entry, base) {
    const fullpath = entry.path;

    if (base !== undefined) {
      entry.path = common.joinPathSegments(base, entry.name, this._settings.pathSegmentSeparator);
    }

    if (common.isAppliedFilter(this._settings.entryFilter, entry)) {
      this._pushToStorage(entry);
    }

    if (entry.dirent.isDirectory() && common.isAppliedFilter(this._settings.deepFilter, entry)) {
      this._pushToQueue(fullpath, base === undefined ? undefined : entry.path);
    }
  }

  _pushToStorage(entry) {
    this._storage.push(entry);
  }

}

exports.default = SyncReader;
export default exports;