import _common from "./common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const common = _common;

class Reader {
  constructor(_root, _settings) {
    this._root = _root;
    this._settings = _settings;
    this._root = common.replacePathSegmentSeparator(_root, _settings.pathSegmentSeparator);
  }

}

exports.default = Reader;
export default exports;