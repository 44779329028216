import _async from "../readers/async";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const async_1 = _async;

class AsyncProvider {
  constructor(_root, _settings) {
    this._root = _root;
    this._settings = _settings;
    this._reader = new async_1.default(this._root, this._settings);
    this._storage = [];
  }

  read(callback) {
    this._reader.onError(error => {
      callFailureCallback(callback, error);
    });

    this._reader.onEntry(entry => {
      this._storage.push(entry);
    });

    this._reader.onEnd(() => {
      callSuccessCallback(callback, this._storage);
    });

    this._reader.read();
  }

}

exports.default = AsyncProvider;

function callFailureCallback(callback, error) {
  callback(error);
}

function callSuccessCallback(callback, entries) {
  callback(null, entries);
}

export default exports;